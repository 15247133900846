import { api } from '../api'

export const INVALIDATE_GAME_SERIES = 'INVALIDATE_GAME_SERIES'
export const REQUEST_GAME_SERIES = 'REQUEST_GAME_SERIES'
export const RECEIVE_GAME_SERIES = 'RECEIVE_GAME_SERIES'

function requestGameSeries() {
    return {
        type: REQUEST_GAME_SERIES,
    }
}

function receiveGameSeries(gameSeries) {
    return {
        type: RECEIVE_GAME_SERIES,
        gameSeries,
        receivedAt: Date.now()
    }
}

function fetchGameSeries(token, filter) {
    return dispatch => {
        dispatch(requestGameSeries(filter))
        return api('games').from('game_series')
            // .token(token)
            .select('game_series_id, name, games(game_id, city)')
            .then(response => response.data)
            .then(json => dispatch(receiveGameSeries(json.sort((a, b) => b.games.length - a.games.length))))
    }
}

function shouldFetchGameSeries(state) {
    const gameSeries = state.gameSeries
    if (!gameSeries) {
        return true
    } else if (gameSeries.isFetching) {
        return false
    } else {
        return gameSeries.didInvalidate
    }
}

export function fetchGameSeriesIfNeeded() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchGameSeries(state)) {
            const { token } = state
            return dispatch(fetchGameSeries(token.token))
        }
    }
}
