import { combineReducers } from 'redux'
import { location, bounds, locations } from './locations'
import { games } from './games'
import { gameSeries } from './game_series'
import { filter } from './filter'
import { color } from './color'
import { credentials, token } from './authentication'
import { cities } from './cities'

const rootReducer = combineReducers({
  cities,
  credentials,
  bounds,
  location,
  filter,
  color,
  games,
  gameSeries,
  locations,
  token
})

export default rootReducer
