export const SELECT_CITY = 'SELECT_CITY'
export const SELECT_GAME_SERIES = 'SELECT_GAME_SERIES'
export const SET_DATE_RANGE = 'SET_DATE_RANGE'

export function selectCity(city) {
  return { type: SELECT_CITY, city }
}

export function selectGameSeries(ids) {
  return {
    type: SELECT_GAME_SERIES,
    ids: ids
  }
}

export function setDateRange(range) {
  return {
    type: SET_DATE_RANGE,
    range
  }
}
