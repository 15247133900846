export const SHOW_LOGIN_FORM = 'SHOW_LOGIN_FORM'
export const HIDE_LOGIN_FORM = 'HIDE_LOGIN_FORM'

export const REQUEST_AUTH_TOKEN = 'REQUEST_AUTH_TOKEN'
export const RECEIVE_AUTH_TOKEN = 'RECEIVE_AUTH_TOKEN'
export const CLEAR_AUTH_TOKEN = 'CLEAR_AUTH_TOKEN'

export function showLoginForm() {
    return {
        type: SHOW_LOGIN_FORM,
    }
}

export function hideLoginForm() {
    return {
        type: HIDE_LOGIN_FORM,
    }
}


export function clearAuthToken() {
    return {
        type: CLEAR_AUTH_TOKEN,
    }
}

function requestAuthToken(login) {
    return {
        type: REQUEST_AUTH_TOKEN,
        login,
    }
}

function receiveAuthToken(token) {
    return {
        type: RECEIVE_AUTH_TOKEN,
        token,
        receivedAt: Date.now()
    }
}

function receiveAuthTokenFailure(error) {
    return {
        type: RECEIVE_AUTH_TOKEN,
        error,
        receivedAt: Date.now()
    }
}


export function fetchAuthToken(login, password) {
    return dispatch => {
        dispatch(requestAuthToken(login))
        let headers = {}
        if (login && password) {
            headers = {
                'Authorization': 'Basic ' + btoa([login, password].join(":"))
            }
        }
        // return fetch(`https://trakar.seslost.cz/matrix-2022/auth`, {
        return fetch(`http://trakar.seslost.localhost:3003/matrix-2022/auth`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: new Headers(headers),
            })
            .then((response) => {
                if (response.ok) {
                    return response.text()
                        .then((token) => dispatch(receiveAuthToken(token)))
                } else {
                    return response.text()
                        .then((err) => { throw new Error('Authentication failure', err) })
                }
            })
            .catch((error) => { dispatch(receiveAuthTokenFailure(error)) })
    }
}


export function fetchAuthTokenIfNeeded() {
    return (dispatch, getState) => {
        const state = getState()
        if (shouldFetchAuthToken(state)) {
            return dispatch(fetchAuthToken())
        } else {
            return Promise.resolve()
        }
    }
}

function shouldFetchAuthToken(state) {
    const { token } = state
    if (!token.token) {
        return true
    } else if (token.isFetching) {
        return false
    }
}
