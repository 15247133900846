import {
  SET_MAP_BOUNDS,
  REQUEST_LOCATIONS,
  RECEIVE_LOCATIONS,
  REQUEST_LOCATION,
  RECEIVE_LOCATION,
  CLEAR_LOCATION
} from '../actions/locations'

import {
  RECEIVE_GAMES,
} from '../actions/games'


export function bounds(state = {}, action) {
  switch (action.type) {
    case SET_MAP_BOUNDS:
      return Object.assign({}, state, {updatedAt: action.updatedAt})
    default:
      return state
  }
}

function locationsWithVisibility(locations, games) {
  const visible = new Map(games.map(g => [g.game_id, g]))
  return locations.map(location => Object.assign({}, location, 
    {visible: visible.has(location.game_id), game: visible.get(location.game_id)}
  ))
}

export function locations(
  state = {
    isFetching: false,
    didInvalidate: true,
    items: [],
    selected: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_GAMES:
      return Object.assign({}, state, {
        items: locationsWithVisibility(state.items, action.games)
      })
    case REQUEST_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.locations,
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}


export function location(
  state = {
    isFetching: false,
    didInvalidate: true,
  },
  action
) {
  switch (action.type) {
    case CLEAR_LOCATION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        item: null
      })
    case REQUEST_LOCATION:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_LOCATION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: action.location[0],
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}
