import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Header, Card } from 'semantic-ui-react'

export default class Location extends Component {

  render() {
    const { location } = this.props
    return (
      <div>
      {location && location.item && <Header content={location.item.name} as="h3" />}
      {location && location.item && location.item.location_papers.map((location_paper) => 
        location_paper.papers.paper_puzzles.map(paper_puzzle =>  {
          const puzzle = paper_puzzle.puzzles
          const paper = location_paper.papers
          // const game = location_paper.papers.games
        return <Card
            key={location_paper.location_paper_id + "-" + puzzle.puzzle_id}
            centered
            image={'https://statek.seslost.cz/sifry/' + puzzle.slug + '?type=thumb'}
            header={puzzle.position + puzzle.tag + " — " + puzzle.name} 
            description={puzzle.description}
            extra={paper.code}
          />
        }))}
      </div>
    )
  }
}

Location.propTypes = {
  location: PropTypes.object.isRequired,
}


            // meta={game.game_series.name + " " + game.start.substr(0, 4)}
