import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from 'semantic-ui-react'

export default class Range extends Component {

  constructor(props) {
    super(props)
    this.handleMinDateChange = this.handleMinDateChange.bind(this)
  }

  handleMinDateChange(event, data) {
    this.props.onChange([new Date(data.value, 0), new Date()])
  }

  render() {
    const { value } = this.props

    const min = 2000
    const options = Array.from(new Array(new Date().getFullYear() - min + 1), (x,i) => min + i).reverse().map(v => ({key: v, value: v, text: v}))
    return (
      <span>
        <Dropdown compact selection options={options} value={value[0].getFullYear()} onChange={this.handleMinDateChange} />
      </span>
    )
  }
}

Range.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}
