import {PostgrestClient, PostgrestResponse} from "@supabase/postgrest-js";

export function api(schema) {
  const location = window.location;
  const protocol = location.protocol;
  const hostname = location.hostname.replace("mapy", "api");
  const port = location.port ? ":6430" : "";
  const url = `${protocol}//${hostname}${port}`;
  const client = new PostgrestClient(url, {schema, throwOnError : false});
  return client;
}
// export const api = new PostgREST('htts://localhost:6430/')
