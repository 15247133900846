import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import './Map.css'

export default class Map extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentLocation: this.props.initialCenter,
            bounds: null
        }
    }

    componentDidMount() {
        this.loadMap()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.bounds !== this.props.bounds) {
            this.recenterMap()
        }
    }

    loadMap() {
        if (window.SMap) {
            this.smap = window.SMap
            const mapRef = this.refs.map
            const node = ReactDOM.findDOMNode(mapRef)

            let { zoom } = this.props
            const { lat, lng } = this.state.currentLocation
            const center = this.smap.Coords.fromWGS84(lat, lng);

            this.map = new this.smap(node, center, zoom);
            this.layer = new this.smap.Layer.Marker();
            this.map.addLayer(this.layer).enable()

            this.map.addDefaultLayer(this.smap.DEF_OPHOTO)
            this.map.addDefaultLayer(this.smap.DEF_OPHOTO0203)
            this.map.addDefaultLayer(this.smap.DEF_OPHOTO0406)
            this.map.addDefaultLayer(this.smap.DEF_TURIST)
            this.map.addDefaultLayer(this.smap.DEF_BASE).enable()

            let layerSwitch = new this.smap.Control.Layer()
            layerSwitch.addDefaultLayer(this.smap.DEF_BASE)
            layerSwitch.addDefaultLayer(this.smap.DEF_OPHOTO)
            layerSwitch.addDefaultLayer(this.smap.DEF_TURIST)
            layerSwitch.addDefaultLayer(this.smap.DEF_OPHOTO0406)
            layerSwitch.addDefaultLayer(this.smap.DEF_OPHOTO0203)
            this.map.addControl(layerSwitch)

            this.map.addDefaultControls()

            this.map.getSignals().addListener(this, "marker-click", (event) => this.props.onMarkerClick(event.target.location_id))
            this.forceUpdate();
        }
    }

    recenterMap() {
        const map = this.map
        const smap = window.SMap
        const locations = this.props.children.map(c => c.props.location).filter(l => l.visible);
        if (!map || !smap || !locations || locations.length === 0)
            return
        let markers = locations.filter(l => l.marker).map(l => l.marker.getCoords())
        let cz = this.map.computeCenterZoom(markers)
        this.map.setCenterZoom(...cz);
    }


    render() {
        const style = { height: '100vh' }
        return (
            <div ref='map' style={style}>
        {this.renderChildren()}
      </div>
        )
    }

    renderChildren() {
        const { children } = this.props

        if (!children) return

        return React.Children.map(children, c => {
            return React.cloneElement(c, {
                smap: this.smap,
                layer: this.layer,
            })
        })
    }
}

Map.propTypes = {
    google: PropTypes.object,
    zoom: PropTypes.number,
    initialCenter: PropTypes.object
}
Map.defaultProps = {
    zoom: 12,
    // San Francisco, by default
    initialCenter: {
        lat: 16.66,
        lng: 49.22
    }
}
