import {
  INVALIDATE_GAME_SERIES,
  REQUEST_GAME_SERIES,
  RECEIVE_GAME_SERIES
} from '../actions/game_series'


export function gameSeries(
  state = {
    isFetching: false,
    didInvalidate: true,
    items: [],
    selected: []
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_GAME_SERIES:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_GAME_SERIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_GAME_SERIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.gameSeries,
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}
