import React, { Component } from 'react'

import  Map from '../components/Map'
import  Marker from '../components/Marker'

export default class MapContainer extends Component {
  render() {
    return (
      <div >
        <Map google={this.props.google} bounds={this.props.bounds} onMarkerClick={this.props.onMarkerClick}>
          {this.props.locations.map((location, _) => {
            return <Marker key={location.location_id} location={ location } color={this.props.color}  />;
          })}
        </Map>
      </div>
    )
  }
}

