import { api }  from '../api'

import { fetchGamesIfNeeded } from './games'

export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS'
export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS'
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS'
export const REQUEST_LOCATION = 'REQUEST_LOCATION'
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION'
export const CLEAR_LOCATION = 'CLEAR_LOCATION'

export function setMapBounds(games) {
  return {
    type: SET_MAP_BOUNDS,
    updatedAt: new Date()
  }
}

function requestLocations() {
  return {
    type: REQUEST_LOCATIONS,
  }
}

function receiveLocations(json) {
  return {
    type: RECEIVE_LOCATIONS,
    locations: json,
    receivedAt: Date.now()
  }
}

export function clearLocation() {
  return {
    type: CLEAR_LOCATION
  }
}

function requestLocation(id) {
  return {
    type: REQUEST_LOCATION,
    id
  }
}

function receiveLocation(json) {
  return {
    type: RECEIVE_LOCATION,
    location: json,
    receivedAt: Date.now()
  }
}

function fetchLocations() {
  return dispatch => {
    dispatch(requestLocations())
    return api('puzzles').from('locations')
      .select('location_id,name,latitude,longitude,game_id')
      .not('longitude', 'is.null').not('latitude', 'is.null')
      .then(response => response.data)
      .then(json => {
        dispatch(receiveLocations(json))
        dispatch(fetchGamesIfNeeded())
      })

  }
}

function shouldFetchLocations(state) {
  const locations = state.locations
  if (!locations) {
    return true
  } else if (locations.isFetching) {
    return false
  } else {
    return locations.didInvalidate
  }
}

export function fetchLocationsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchLocations(getState())) {
      return dispatch(fetchLocations())
    }
  }
}


function fetchLocation(id) {
  return dispatch => {
    dispatch(requestLocation(id))
    return api('puzzles').from('locations')
      .select(`location_id,  name, latitude, longitude, 
        location_papers!location_papers_location_id_fkey(location_paper_id, 
          papers(paper_id, code, 
            paper_puzzles(paper_puzzle_id, 
              puzzles(puzzle_id, tag, position, name, slug, description))
            )
        )
        `)
      .eq('location_id', id)
      .then(response => response.data)
      .then(json => dispatch(receiveLocation(json)))
  }
}

function shouldFetchLocation(state, id) {
  const location = state.location
  if (!location) {
    return true
  } else if (location.location_id !== id) {
    return true
  } else if (location.isFetching) {
    return false
  }
}

export function fetchLocationIfNeeded(id) {
  return (dispatch, getState) => {
    if (shouldFetchLocation(getState(), id)) {
      return dispatch(fetchLocation(id))
    }
  }
}

