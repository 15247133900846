import React, { Component } from 'react'
import { Button, Modal, Form, Segment, Message } from 'semantic-ui-react'


class ModalLoginForm extends Component {

    state = { login: '', password: '' }

    handleChange = (e, { name, value }) => this.setState({
        [name]: value
    })

    handleSubmit = () => {
        const { login, password } = this.state
        this.props.onSubmit(login, password)
    }
    render() {
        const { login, password } = this.state
        const { error } = this.props

        return <Modal open = { this.props.open }>
        <Modal.Header>Trasy šifrovacích her </Modal.Header> 
        <Modal.Content>
          <Modal.Description>
            <Form  error={error !== null}>
              <Segment stacked>
                 <Message
                    error
                    header='Příhlášení se nepodařilo'
                    content={error && error.message}
                  />
                <Form.Input 
                  fluid 
                  icon='user' 
                  iconPosition='left' 
                  placeholder='E-mail address'
                  name='login'
                  value={login}
                  onChange={this.handleChange}
                />
                    <Form.Input
                      fluid
                      icon='lock'
                      iconPosition='left'
                      placeholder='Password'
                      type='password'
                      name='password'
                      onChange={this.handleChange}
                      value={password}
                    />
                        <Button  fluid primary onClick={this.handleSubmit}>
                          Přihlásit
                        </Button>
                      </Segment>
                    </Form>
                  </Modal.Description> 
                </Modal.Content> 
              </Modal>
    }
}

export default ModalLoginForm
