export const SET_DEFAULT_VALUES = 'SET_DEFAULT_VALUES'

function stripInitialSlash(path) {
    if (path && path[0] === '/')
        return path.substr(1)
    else
        return path
}


export function setDefaultValues() {
    const params = new URLSearchParams(document.location.search)
    const game_name = stripInitialSlash(document.location.pathname) || 'matrix-2019'
    const schema = params.get("schema")
    return {
        type: SET_DEFAULT_VALUES,
        game_name,
        schema

    }
}
