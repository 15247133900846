import {
    SHOW_LOGIN_FORM,
    HIDE_LOGIN_FORM,
    REQUEST_AUTH_TOKEN,
    RECEIVE_AUTH_TOKEN,
    CLEAR_AUTH_TOKEN,
} from '../actions/api'


export function token(
    state = {
        isFetching: false,
        didInvalidate: true,
    },
    action
) {
    switch (action.type) {
        case CLEAR_AUTH_TOKEN:
            return Object.assign({}, state, {
                didInvalidate: true
            })
        case REQUEST_AUTH_TOKEN:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            })
        case RECEIVE_AUTH_TOKEN:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                token: action.token,
                lastUpdated: action.receivedAt
            })
        default:
            return state
    }
}


export function credentials(
    state = {
        openedLoginForm: false,
        error: null
    },
    action
) {
    switch (action.type) {
        case RECEIVE_AUTH_TOKEN:
            return Object.assign({}, state, {
                openedLoginForm: !!action.error,
                error: action.error
            })
        case HIDE_LOGIN_FORM:
            return Object.assign({}, state, {
                openedLoginForm: false,
                error: null
            })
        case SHOW_LOGIN_FORM:
            return Object.assign({}, state, {
                openedLoginForm: true,
            })
        default:
            return state
    }
}
