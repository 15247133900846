import {
  INVALIDATE_GAME_SERIES,
  REQUEST_GAME_SERIES,
  RECEIVE_GAME_SERIES
} from '../actions/game_series'


export function cities(
  state = {
    isFetching: false,
    didInvalidate: true,
    items: [],
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_GAME_SERIES:
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case REQUEST_GAME_SERIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RECEIVE_GAME_SERIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: Array.from(new Set(action.gameSeries.flatMap(gs => gs.games.filter(g => g.city).flatMap(g => g.city)))),
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}
