import { Component } from 'react'
import PropTypes from 'prop-types'
import { COLOR_GAME_SERIES, COLOR_GAME } from '../reducers/color'
import './Marker.css'

export default class Marker extends Component {
  componentDidMount() {
    this.renderMarker()
  }
  componentDidUpdate(prevProps) {
    if(prevProps.map !== this.props.map || 
      prevProps.location.visible !== this.props.location.visible ||
      prevProps.color !== this.props.color
    ) {
      this.renderMarker()
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null)
    }
  }

  color(location) {
    return this.colorGame(location.game)
  }

  colorGame(game) {
    return this.props.color.palette[this.colorKey(game)] || 'black'
  }

  colorKey(game) {
    switch(this.props.color.group) {
      case COLOR_GAME_SERIES:
        return game.game_series.game_series_id
      case COLOR_GAME:
        return game.game_id
      default:
        return game.game_id
    }
  }

  opacity(location) {
    let start = location.game.start
    return Math.max(0.2, 1 - ((new Date().getTime() - start) / (5 * 365 * 24 * 60 * 60 * 1000)))
  }

  label(location) {
    return (location.game.start.getFullYear() % 10).toString()
  }

  url(location) {
    let div = document.createElement('div')
    div.className = 'marker ui circular label ' + this.color(location)
    div.innerHTML = location.game.volume
    return div
  }

  title(location) {
    const game = location.game
    const year = new Date(Date.parse(game.start)).getFullYear()
    const title = game.game_series.name
    return [title, year].join(" ")
  }

  onClick(id) {
    return () => this.props.onClick(id)
  }


  renderMarker() {
    let { smap, layer, location } = this.props

    if(!smap)
      return

    if (location.visible) {
      if(!this.marker) {
        const coords = smap.Coords.fromWGS84(location.longitude, location.latitude);
        const label = this.url(location)
        this.marker = new smap.Marker(coords, null, {
          title: this.title(location),
          url: label
        })
        this.marker.location_id = location.location_id
        this.marker.label = label
        location.marker = this.marker
      }
      this.marker.label.className = 'marker ui circular label ' + this.color(location)
      layer.addMarker(this.marker);

    } else if (this.marker) {
      layer.removeMarker(this.marker)
    }
  }

  render() {
    return null
  }
}

Marker.propTypes = {
  location: PropTypes.object,
  map: PropTypes.object
}
