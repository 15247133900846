import {
  RECEIVE_GAMES,
} from '../actions/games'

export const COLOR_GAME_SERIES = 'GAME_SERIES'
export const COLOR_GAME = 'GAME'
export const COLOR_POSITION = 'PUZZLE_POSITION'

export function color(
  state = {
    group: COLOR_GAME_SERIES, 
    palette: {}
  },
  action
) {
  // const colors = ['#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92']
  switch (action.type) {
    case RECEIVE_GAMES:
      let games = action.games
      let game_series_count = new Set(games.map(g => g.game_series.game_series_id)).size

      if(game_series_count > 1) { 
        return {
          group: COLOR_GAME_SERIES,
          palette: palette(games, (game) => game.game_series.game_series_id)
        }
      } else {
        return {
          group: COLOR_GAME,
          palette: palette(games, (game) => game.game_id)
        }
      }
    default:
      return state
  }
}

function palette(games, getKey) {
  const colors = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey']
  return games.reduce((palette, game) => {
    const key = getKey(game)
    if(!palette[key]) {
      palette[key] = colors[Object.keys(palette).length % colors.length]
    }
    return palette
  }, {})
}
