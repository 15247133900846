import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Header, Divider, Button, Tab } from 'semantic-ui-react'

import { setDefaultValues } from '../actions/init'
import { fetchLocationsIfNeeded, fetchLocationIfNeeded, clearLocation } from '../actions/locations'
import { fetchGamesIfNeeded, invalidateGames } from '../actions/games'
import { fetchGameSeriesIfNeeded } from '../actions/game_series'
import { setDateRange, selectGameSeries, selectCity } from '../actions/filter'
import { hideLoginForm, fetchAuthToken, fetchAuthTokenIfNeeded } from '../actions/api'


import Games from '../components/Games'
import ModalLoginForm from '../components/ModalLoginForm'

import Filter from '../containers/Filter'
import Location from '../containers/Location'
import Map from '../containers/MapContainer'

class MapApp extends Component {

    constructor(props) {
        super(props)
        this.handleGameSeriesChange = this.handleGameSeriesChange.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleCityChange = this.handleCityChange.bind(this)
        this.handleLocationClick = this.handleLocationClick.bind(this)
        this.handleClearLocationClick = this.handleClearLocationClick.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(setDefaultValues())
        dispatch(fetchLocationsIfNeeded())
        dispatch(fetchGameSeriesIfNeeded())
    }

    componentDidUpdate(prevProps) {
        if (this.props.games !== prevProps.games) {
            const { dispatch } = this.props
            dispatch(fetchGameSeriesIfNeeded())
            dispatch(fetchGamesIfNeeded())
        }
    }

    handleSubmitLoginForm(login, password) {
        const { dispatch } = this.props
        dispatch(fetchAuthToken(login, password))
    }

    handleCancelLoginForm() {
        this.props.dispatch(hideLoginForm())
    }

    handleGameSeriesChange(ids) {
        const { dispatch } = this.props
        dispatch(selectGameSeries(ids))
        dispatch(invalidateGames())
        dispatch(fetchGamesIfNeeded())
    }

    handleDateChange(range) {
        this.props.dispatch(setDateRange(range))
        this.props.dispatch(invalidateGames())
        this.props.dispatch(fetchGamesIfNeeded())
    }

    handleLocationClick(id) {
        this.props.dispatch(fetchLocationIfNeeded(id))
    }

    handleClearLocationClick(id) {
        this.props.dispatch(clearLocation())
    }

    handleCityChange(event, data) {
        if (data.value) {
            this.props.dispatch(selectCity(data.value))
            this.props.dispatch(invalidateGames())
            this.props.dispatch(fetchGamesIfNeeded())
        }
    }

    groupBy(arr, f) {
        return arr.reduce((result, item) => {
            const key = f(item)
            if (!result[key])
                result[key] = []
            result[key].push(item)
            return result
        }, {})
    }

              // <Games color={this.props.color} games={this.groupBy(games.items.filter(g => g.papers.flatMap(p => p.location_papers.filter(lp => lp.locations && lp.locations.latitude && lp.locations.longitude)).length > 0).sort((a,b) => b.start > a.start), (g) => g.start.substr(0,4))} />
    render() {
        const { cities, location, filter, bounds, games, gameSeries, locations, credentials } = this.props
        const panes = [{
                render: () =>
                    <Tab.Pane>
            <Header as="h2" content='Mapa šifrovacích her' />
            <Filter filter={filter} gameSeries={gameSeries}  cities={cities}
                onDateChange={this.handleDateChange }
                onGameSeriesChange={this.handleGameSeriesChange }
                onCityChange={this.handleCityChange } />
              <Divider />
          </Tab.Pane>
            },
            {
                render: () =>
                    <Tab.Pane loading={location.isFetching}>
            <Button icon='close' basic floated='right' onClick={this.handleClearLocationClick}/>
            <Divider clearing={true} hidden />
            <Location location={location}/>
          </Tab.Pane>
            },
        ]

        return (
            <div className='container'>
        <ModalLoginForm open={credentials.openedLoginForm} error={credentials.error} onSubmit={this.handleSubmitLoginForm} onCancel={this.handleCancelLoginForm} />
        <Map locations={locations.items} bounds={bounds} color={this.props.color} onMarkerClick={this.handleLocationClick}/>
        <Tab panes={panes} activeIndex={location.item ? 1 : 0 }/>
      </div>
        )
    }
}

MapApp.propTypes = {
    games: PropTypes.object.isRequired,
    locations: PropTypes.object.isRequired,
    lastUpdated: PropTypes.number,
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    const { cities, location, color, filter, bounds, locations, games, gameSeries, credentials } = state

    return {
        color,
        location,
        filter,
        bounds,
        games,
        credentials,
        gameSeries: gameSeries.items.map(gs => ({ value: gs.game_series_id, text: gs.name })),
        cities: cities.items.map(city => ({ value: city, text: city })),
        locations,
    }
}

export default connect(mapStateToProps)(MapApp)
