import {api} from '../api'

import {setMapBounds} from './locations'

export const INVALIDATE_GAMES = 'INVALIDATE_GAMES'
export const REQUEST_GAMES = 'REQUEST_GAMES'
export const RECEIVE_GAMES = 'RECEIVE_GAMES'

export function invalidateGames() {
  return { type: INVALIDATE_GAMES, }
}

function requestGames(filter) {
  return { type: REQUEST_GAMES, filter }
}

function receiveGames(games) {
  return { type: RECEIVE_GAMES, games, receivedAt: Date.now() }
}

function request(filter) {
  let req = api('games').from('games').select(`game_id, name, volume, start, game_series_id,
      game_series(game_series_id, name)`)
  if (filter.gameSeriesIds) {
    req = req.in('game_series_id', filter.gameSeriesIds)
  }
  if (filter.dateRange) {
    let [from, to] = filter.dateRange.map(d => d.toISOString())
    req = req.gt('start', from).lt('start', to)
  }
  if (filter.city) {
    req = req.match({city : filter.city})
  }
  return req
}

function fetchGames(filter) {
  return dispatch => {
    dispatch(requestGames(filter))
        return request(filter)
            .then(response => response.data)
            .then(json => {
                dispatch(receiveGames(json))
                dispatch(setMapBounds())
            })
  }
}

function shouldFetchGames(state) {
  const games = state.games
  if (!games) {
    return true
  }
  else if (games.isFetching) {
    return false
  }
  else {
    return games.didInvalidate
  }
}

export function fetchGamesIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchGames(getState())) {
      return dispatch(fetchGames(getState().filter))
    }
  }
}
