import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form, Dropdown } from 'semantic-ui-react'

import Range from '../components/Range'

export default class Filter extends Component {

  onGameSeriesChange = (event, data) => {
    return this.props.onGameSeriesChange(data.value)
  }

  render() {
    const { filter, cities, gameSeries, onDateChange, onCityChange } = this.props
    const { city,  dateRange, gameSeriesIds } = filter
    return (
      <Form>
        <Form.Field>
          <label>Od roku</label>
          <Range value={dateRange} onChange={onDateChange} />
        </Form.Field>
        <Form.Field>
          <label>Šifrovačka</label>
          <Dropdown fluid floating multiple selection closeOnChange search options={gameSeries} 
            value={gameSeriesIds || []} onChange={this.onGameSeriesChange} />
        </Form.Field>
        <Form.Field>
          <label>Město</label>
          <Dropdown placeholder='Praha' search selection 
            options={cities} 
            value={city} 
            onChange={onCityChange} />
        </Form.Field>
      </Form>
    )
  }
}

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  gameSeries: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onGameSeriesChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired
}

