import {
  SET_DATE_RANGE,
  SELECT_CITY,
  SELECT_GAME_SERIES
} from '../actions/filter'

export function filter(
  state = {
    gameSeriesIds: null,
    dateRange: [new Date(2013, 0), new Date()],
    city: 'Praha',
  },
  action
) {
  switch (action.type) {
    case SELECT_CITY:
      return Object.assign({}, state, {gameSeriesIds: null, city: action.city})
    case SELECT_GAME_SERIES:
      return Object.assign({}, state, {gameSeriesIds: action.ids, city: null})
    case SET_DATE_RANGE:
      return Object.assign({}, state, {dateRange: action.range})
    default:
      return state
  }
}
